import React from 'react';
import 'devextreme/data/odata/store';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import Mozgasterapia from '../../components/static-contents/Mozgasterapia';
import JatekMatek from '../../components/static-contents/JatekMatek';
import Muveszetterapia from '../../components/static-contents/MuveszetTerapia';
import './Segitunk.scss';
import PageTitle from '../../components/pageItems/PageTitle';

export default function Segitunk() {
  return (
    <React.Fragment>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <PageTitle title={'Hogyan segítünk?'} />

          <TabPanel showNavButtons={true}>
            <Item title='Mozgásterápia' icon='fa-solid fa-person-walking'>
              <Mozgasterapia />
            </Item>
            <Item title='Játékos MATEMATIKA' icon='fa-solid fa-calculator'>
              <JatekMatek />
            </Item>
            <Item
              title='Stresszoldó MŰVÉSZTERÁPIA'
              icon='fa-solid fa-palette'
              badge='újdonság'
            >
              <Muveszetterapia />
            </Item>
          </TabPanel>
        </div>
      </div>
    </React.Fragment>
  );
}
