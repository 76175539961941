import Button from 'devextreme-react/button';
import React, { useCallback } from 'react';
import './PageTitle.scss';
import { useNavigate } from 'react-router-dom';

export default function PageTitle({ title, toggleMenu }) {
  const navigate = useNavigate();
  const goHome = useCallback(async (e) => {
    navigate('/home');
  }, []);
  return (
    <React.Fragment>
      <div className='PageTitle'>
        <Button
          className='nav-home'
          icon='home'
          type='danger'
          onClick={goHome}
        ></Button>
        <h2>{title}</h2>
      </div>
    </React.Fragment>
  );
}
