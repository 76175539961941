import axios from 'axios';
import clientConfig from '../client-config';

export async function getBlogList() {
  let res;
  try {
    res = await axios.get(clientConfig.backendHOST + '/blog/BlogList');
  } catch (e) {
    console.error(e);
  }

  if (res) {
    return {
      isOk: true,
      data: res.data,
    };
  } else {
    return {
      isOk: false,
    };
  }
}

export async function getBlogContent(pageName) {
  let res;
  try {
    res = await axios.get(clientConfig.backendHOST + '/blog/BlogContent', {
      params: {
        page: pageName,
      },
    });
  } catch (e) {
    console.error(e);
  }

  if (res) {
    return {
      isOk: true,
      data: res.data,
    };
  } else {
    return {
      isOk: false,
    };
  }
}
