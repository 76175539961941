import React from 'react';

import Box, { Item } from 'devextreme-react/box';
import Map from 'devextreme-react/map';
import PageTitle from '../../components/pageItems/PageTitle';

export default function Contact() {
  return (
    <React.Fragment>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <PageTitle title={'Kapcsolat'} />

          <div className={'content-block'}>
            <h2>PALÁNTA</h2>

            <Box direction='row' width='100%'>
              <Item ratio={2}>
                <Box direction='col' width='100%' height={130}>
                  <Item ratio={1}>
                    <Box direction='row' width='100%'>
                      <Item ratio={1}>
                        <p>
                          <strong>Telefon:</strong>
                        </p>
                      </Item>
                      <Item ratio={2}>
                        <p>+36704185772</p>
                      </Item>
                    </Box>
                  </Item>
                  <Item ratio={1}>
                    <Box direction='row' width='100%'>
                      <Item ratio={1}>
                        <p>
                          <strong>E-Mail:</strong>
                        </p>
                      </Item>
                      <Item ratio={2}>
                        <p>palantasopron@gmail.com</p>
                      </Item>
                    </Box>
                  </Item>
                  <Item ratio={1}>
                    <Box direction='row' width='100%'>
                      <Item ratio={1}>
                        <p>
                          <strong>Lugas:</strong>
                        </p>
                      </Item>
                      <Item ratio={2}>
                        <p>9400 Sopron, Káposztás utca 21.</p>
                      </Item>
                    </Box>
                  </Item>{' '}
                  <Item ratio={1}>
                    <Box direction='row' width='100%'>
                      <Item ratio={1}>
                        <p>
                          <strong>Web:</strong>
                        </p>
                      </Item>
                      <Item ratio={2}>
                        <p>www.palantasopron.hu</p>
                      </Item>
                    </Box>
                  </Item>
                </Box>
              </Item>
            </Box>
            <Map
              defaultCenter='47.6904952, 16.5840453'
              defaultZoom={16}
              height={400}
              width='100%'
              provider='google'
              type={'roadmap'}
              markers={[
                {
                  location: [47.6904952, 16.5840453],
                  tooltip: {
                    text: 'Scriptantia',
                  },
                },
              ]}
              apiKey={'AIzaSyD6MKTdY1u2MePwENHt_NlbRvUdH8aYiYU'}
            ></Map>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
