import React, { useEffect, useState } from 'react';
import 'devextreme/data/odata/store';
import { getBlogList } from '../../api/blog';
import BlogItem from '../../components/blog/BlogItem';
import { Routes, Route, useNavigate } from 'react-router-dom';
import BlogPage from './BlogPage';
import PageTitle from '../../components/pageItems/PageTitle';

export default function Blog(props) {
  const [blogList, setBlogList] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    async function apiCall() {
      const apiResponse = await getBlogList();

      if (apiResponse) {
        setBlogList(apiResponse.data);
      }
    }
    apiCall();
  }, []);

  return (
    <React.Fragment>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <PageTitle title={'Blogbejegyzések'} />

          {blogList &&
            blogList.length > 0 &&
            blogList.map((bl) => (
              <React.Fragment>
                <BlogItem
                  key={bl.id}
                  title={bl.title}
                  summary={bl.summary}
                  onBlogClick={() => {
                    navigate(`/blogPage/${bl.page_address}`);
                  }}
                  titleColor={bl.title_text_color}
                  summaryColor={bl.summary_text_color}
                  bgColor={bl.bg_color}
                ></BlogItem>
              </React.Fragment>
            ))}
          <Routes>
            {blogList &&
              blogList.length > 0 &&
              blogList.map((bl) => (
                <Route
                  exact
                  path={`/blogPage/:${bl.page_address}`}
                  Component={BlogPage}
                />
              ))}
          </Routes>
        </div>
      </div>
    </React.Fragment>
  );
}
