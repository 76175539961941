import 'devextreme/data/odata/store';
import React from 'react';

import PageTitle from '../../components/pageItems/PageTitle';
import './hirek.scss';

export default function Hirek() {
  return (
    <React.Fragment>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <PageTitle title={'Híreink'} />
          <div>
            <p>
              <strong>Augusztus 21-től</strong> megkezdjük a felméréseket a
              szeptemberi terápiákra, órákra.
            </p>
          </div>
          <div>
            <p>
              <strong>Augusztus 26-án</strong> kézműves foglalkozást tartunk a
              2023-as Közösségek Találkozóján rendezvényén Jászkarajenőn. Ha
              arra jársz, vagy van kedved gyere s csatlakozz.
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
