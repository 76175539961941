import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useCallback,
} from 'react';
import { signIn as sendSignInRequest } from '../api/auth';

function AuthProvider(props) {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
    /*
    (async function () {
      if (loading) {
        const result = await getUser();
        if (result.isOk) {
          setUser(result.data);
        }

        setLoading(false);
      }
    })();
    */
  }, [loading]);

  const signIn = useCallback(async (loginData) => {
    const result = await sendSignInRequest(loginData);
    if (result.isOk) {
      localStorage.setItem('token', result.data.token);
      setLoading(true);
    }

    return result;
  }, []);

  const ReloadUserRights = () => {
    setLoading(true);
  };

  const signOut = useCallback(() => {
    setUser(undefined);
    localStorage.removeItem('token');
  }, []);

  return (
    <AuthContext.Provider
      value={{ user, signIn, signOut, ReloadUserRights, loading }}
      {...props}
    />
  );
}

const AuthContext = createContext({ loading: false });
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
