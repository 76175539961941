import React from 'react';
import './BlogItem.scss';

const BlogItem = ({
  title,
  summary,
  onBlogClick,
  titleColor,
  summaryColor,
  bgColor,
}) => {
  console.log(bgColor);
  return (
    <React.Fragment>
      <div
        className='BlogItemBox'
        onClick={onBlogClick}
        style={{ backgroundColor: bgColor }}
      >
        <h2 className='BlogItemTitle' style={{ color: titleColor }}>
          {title}
        </h2>

        <div style={{ color: summaryColor }}>
          <p>{summary}</p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BlogItem;
