import React from 'react';
import { useNavigate } from 'react-router';
import './NavigationTile.scss';
import Button from 'devextreme-react/button';

const NavigationTile = ({ title, imageLink, buttonText, navigateTo }) => {
  const navigate = useNavigate();

  function onDetailsClick(e) {
    navigate(navigateTo);
  }

  return (
    <React.Fragment>
      <div
        className='NavTile'
        style={{ backgroundImage: `url(${imageLink})` }}
        onClick={onDetailsClick}
      >
        <h2 className='NavTileTitle'>{title}</h2>
        <Button className='NavButton'>{buttonText}</Button>
      </div>
    </React.Fragment>
  );
};

export default NavigationTile;
