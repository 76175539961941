import Button from 'devextreme-react/button';
import 'devextreme/data/odata/store';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './StaticContent.scss';

export default function JatekMatek() {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div className={'content-block static-page'}>
        <h2 className={'content-block title'}>Játékos matematika</h2>
        <p>
          A gyermek érdeklődése és aktivitása során tapasztalatokat szerez a
          szűkebb és tágabb, természeti, tárgyi környezetérő és ez által
          összefüggéseket tár fel formai, mennyiségi, téri viszonyairól. A
          gyermek miközben felfedezi környezetét, olyan tapasztalatok britokába
          jut, amelyek a környezetben való, életkorának megfelelő biztos
          eligazodáshoz, tájékozódáshoz szükségesek.
        </p>
        <p>
          Azért, hogy a gyermeki felfedező tevékenység még hatékonyabban
          fejleszthesse a kialakuló- félben lévő, vagy már meglévő készségeket,
          ma már speciális fejlesztő játékok is állnak rendel- kezésünkre, de
          sose szabad elfelejteni, hogy ezek a játékok csupán eszközök, a
          gyermeket hagyni kell a mindennapi tevékenységek által is felfedezi a
          világot, azt, hogy hogyan is műkö- dik a világ, a rész-egész. Akárcsak
          egy sütemény sütésnél a méricskélés, vagy egy nagy séta alkalmával az
          óriás lépések, apró ugrások.
        </p>
        <p>
          Minden gyermekben él a cselekvés vágya és a világ megismerésének
          igénye, így biztosítani kell számukra a spontán, szabad tevékenység
          lehetőségét is, a megfelelő környezetet.
        </p>
        <p>
          Játékos matematikai foglalkozásunk, ezen elvek alapján valósul meg.
          Játékos formában keltjük fel a gyermek érdeklődését a matematika
          iránt, a gyermek kíváncsiságára építő helyzetek által közelebb kerül a
          számok világához. A játék során kihasználjuk a spontán adódó
          matematikai helyzeteket, megismertetjük és alkalmazzuk a logikai és
          matematikai szabályjátékokkal.
        </p>
        <p>
          A matematikához való pozitív hozzáállás kialakítása, kis létszámú
          csoportos foglalkozás keretein belül, vagy egyénileg valósul meg.
          Csoportjainkba sok szeretettel várjuk a 4-12 éves korosztályú
          gyermekeket.
        </p>
        <p>
          A 12 év feletti korosztály számára az alábbi linken elérhető
          feladattár: ...
        </p>
        <div className='buttons'>
          <Button
            className='button'
            type='success'
            onClick={(e) => navigate('/bejelentkezes')}
          >
            Jelentkezés felmérésre
          </Button>
          <Button
            className='button'
            type='success'
            onClick={(e) => navigate('/arlista')}
          >
            Árlista
          </Button>
        </div>
        <p>
          <dl>
            <dt>Foglalkozást vezető pedagógusaink:</dt>
            <dd>
              <strong>Kincses Tímea </strong>- óvodapedagógus és mozgásterapeuta
            </dd>
            <dd>
              <strong>Fehér Flóra </strong>- gyógypedagógus
            </dd>
          </dl>
        </p>
      </div>
    </React.Fragment>
  );
}
