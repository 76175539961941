import React from 'react';
import './home.scss';
import NavigationTile from '../../components/navigation-tiles/NavigationTile';

export default function Home() {
  return (
    <React.Fragment>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <div className='navigation-cards'>
            <NavigationTile
              title={'Kik vagyunk?'}
              imageLink={'./img/tiles/kikvagyunk.jpg'}
              navigateTo={'/kik-vagyunk'}
              buttonText={'Megnézem'}
            />
            <NavigationTile
              title={'Hogyan segítünk?'}
              imageLink={'./img/tiles/hogyansegithetunk.jpg'}
              navigateTo={'/hogyan-segitunk'}
              buttonText={'Megnézem'}
            />
            <NavigationTile
              title={'Lugas'}
              imageLink={'./img/tiles/lugas.jpg'}
              navigateTo={'/login'}
              buttonText={'Megnézem'}
            />
            <NavigationTile
              title={'Podcastok'}
              imageLink={'./img/tiles/podcast.jpg'}
              navigateTo={'/login'}
              buttonText={'Megnézem'}
            />
            <NavigationTile
              title={'Blog'}
              imageLink={'./img/tiles/blog.jpg'}
              navigateTo={'/blog'}
              buttonText={'Megnézem'}
            />
            <NavigationTile
              title={'Híreink'}
              imageLink={'./img/tiles/hirek.jpg'}
              navigateTo={'/login'}
              buttonText={'Megnézem'}
            />{' '}
            <NavigationTile
              title={'Kapcsolat'}
              imageLink={'./img/tiles/elerhetoseg.jpg'}
              navigateTo={'/kapcsolat'}
              buttonText={'Megnézem'}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
