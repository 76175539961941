import Button from 'devextreme-react/button';
import 'devextreme/data/odata/store';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './StaticContent.scss';

export default function Muveszetterapia() {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div className={'content-block static-page'}>
        <h2 className={'content-block title'}>Stresszoldó művészterápia</h2>
        <p>
          A mai rohanó világunkban, nem csak a felnőtteket éri nap, mint nap sok
          megpróbáltatás, hanem a gyermekek is ki vannak téve ennek a
          veszélynek. Megfelelni a felnőttek elvárásainak, megküzdeni a
          nehézségeikkel, esetleges hátrányaikkal nem könnyű feladat számukra.
        </p>
        <p>
          Erre alakult meg stresszoldó művészterápiánk, amely két részből
          tevődik össze. A terápia első 1/3-ad részében egy speciális
          mozgásformával segítjük a gyermekeket a napi stressz feloldásában. A
          mozgás fejleszti idegrendszerüket, de nem specifikusan az adott
          gyermekre ráépülő mozgásforma, hanem egy kötetlenebb csoportos mozgás,
          ami segít a gyermekekben felgyülemlett stressz oldásában. Ezáltal a
          gyermeke kiegyensúlyozottabb, boldogabb és felszabadultabb lesz. A
          stressztorna után a gyerekek önfeledten adhatják át magukat a
          művészetnek. Amely festés, agyag, szénrajz, pasztelkréta, stb..
          tecnikákkal, az éppen adott heti témától függően alkotnak a gyerekek
          pedagógus segítségével. Miközben csökken a stressz bennük és kisimul a
          lelkük, fejlődik zenei ízlésviláguk és növekszik fantáziájuk.
          Stresszoldó művészterápiánk elérhető 4-12 éves gyermekek számára.
          Korcsoport szerinti beosztásban.
        </p>

        <p>
          <dl>
            <dt>Terápiát vezető pedagógusaink:</dt>
            <dd>
              <strong>Kincses Tímea </strong>- óvodapedagógus és mozgásterapeuta
            </dd>
            <dd>
              <strong>Fehér Flóra </strong>- gyógypedagógus
            </dd>
          </dl>
        </p>
        <div className='buttons'>
          <Button
            className='button'
            type='success'
            onClick={(e) => navigate('/bejelentkezes')}
          >
            Jelentkezés felmérésre
          </Button>
          <Button
            className='button'
            type='success'
            onClick={(e) => navigate('/arlista')}
          >
            Árlista
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
}
