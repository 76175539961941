import ContextMenu, { Position } from 'devextreme-react/context-menu';
import List from 'devextreme-react/list';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import './UserPanel.scss';

export default function UserPanel({ menuMode }) {
  const { user, signOut } = useAuth();
  const navigate = useNavigate();

  function navigateToProfile() {
    navigate('/profile');
  }
  function navigateLogin() {
    navigate('/login');
  }
  const menuItems = useMemo(
    () =>
      user
        ? [
            {
              text: 'Profilom',
              icon: 'fa-regular fa-user',
              onClick: navigateToProfile,
            },
            {
              text: 'Kilépés',
              icon: 'runner',
              onClick: signOut,
            },
          ]
        : [
            {
              text: 'Belépés',
              icon: 'fa-regular fa-user',
              onClick: navigateLogin,
            },
          ],
    [signOut]
  );
  return (
    <div className={'user-panel'}>
      <div className={'user-info'}>
        <i className='fa-regular fa-user profile'></i>
      </div>

      {menuMode === 'context' && (
        <ContextMenu
          items={menuItems}
          target={'.user-button'}
          showEvent={'dxclick'}
          width={210}
          cssClass={'user-menu'}
        >
          <Position my={'top center'} at={'bottom center'} />
        </ContextMenu>
      )}
      {menuMode === 'list' && (
        <List className={'dx-toolbar-menu-action'} items={menuItems} />
      )}
    </div>
  );
}
