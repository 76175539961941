import Button from 'devextreme-react/button';
import 'devextreme/data/odata/store';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './StaticContent.scss';

export default function Mozgasterapia() {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div className={'content-block static-page'}>
        <h2 className={'content-block title'}>Komplex-mozgásterápia</h2>
        <p>
          A komplex-mozgásterápia egy pedagógiai fejlesztőeljárás, tanulási
          nehézségek megelőzésére és a tanulási nehézségekkel küzdő gyermekek
          fejlesztésére.
        </p>
        <p>
          Az idegrendszer érése, szoros kapcsolatban áll a mozgás fejlődésével.
          Az éretlen idegrendszer különféle elakadásokat okozhat a kisgyermekek
          életében, amely jelentkezhet tanulási vagy beilleszkedési
          nehézségként. A komplex-mozgásterápia ezen deficitek csökkentésében
          vagy megszüntetésében segíthet. Mozgásterápia során a gyermek újra
          élheti mozgásfejlődéseinek bizonyos szakaszait, kijavíthatja azok
          hibás működését, ami által a gyermek idegrendszere újraszerveződik.
        </p>
        Területek:
        <ul>
          <li>írás, olvasás, számolási nehézségek </li>
          <li>finommotorika fejlesztése </li>
          <li>tanulási nehézségek </li>
          <li>megkésett beszédfejlődés</li>
          <li>figyelemkoncentrációs zavar</li>
          <li>iskolaéretlenség</li>
        </ul>
        <p>
          A terápia által javul a gyermek kifejezőkészsége, kis- és nagymozgások
          összerendezettebbek lesznek, javul ceruzafogásuk, szem-kéz
          koordináció, finommozgások egyre pontosabbá válnak, fejlődik
          egyensúlyérzékük és kialakul a testséma, s a dominanciák. Ezáltal nő
          az önbizalmuk, nagyobb lesz kitartásuk és sokkal könnyebben birkóznak
          meg az iskolai élettel. A terápia az iskola megkezdése előtt nagy
          segítség lehet a gyermekek számára, de ugyanúgy kisiskolás korban is
          ajánljuk.
        </p>
        <p>
          A foglalkozásokra 4 éves kortól egészen 16 éves korig várjuk a
          gyermekeket. A foglalkzás kis létszámú csoportokban (max 5 fő) zajlik,
          melyet egy 1,5-2 órás játékos részképesség felmérés előz meg.{' '}
        </p>
        <dl>
          <dt>Terápiát vezető pedagógusaink:</dt>
          <dd>
            <strong>Kincses Tímea </strong>- óvodapedagógus és mozgásterapeuta
          </dd>
          <dd>
            <strong>Fehér Flóra </strong>- gyógypedagógus
          </dd>
        </dl>
        <div className='buttons'>
          <Button
            className='button'
            type='success'
            onClick={(e) => navigate('/bejelentkezes')}
          >
            Jelentkezés felmérésre
          </Button>
          <Button
            className='button'
            type='success'
            onClick={(e) => navigate('/arlista')}
          >
            Árlista
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
}
