import 'devextreme/data/odata/store';
import React from 'react';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import PageTitle from '../../components/pageItems/PageTitle';
import './araink.scss';
export default function Arlista() {
  pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url
  ).toString();

  return (
    <React.Fragment>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <PageTitle title={'Aktuális áraink'} />
          <div>
            <h6 className='price-block-title'>Mogzásfejlesztés árlista</h6>
            <table className='price-table'>
              <tbody>
                <tr className='price-table-header'>
                  <th className='price-col-1'>
                    A vizsgálat/foglalkozás típusa
                  </th>
                  <th className='price-col-2' colspan='2'>
                    Szolgáltatás díja óránként vagy alkalmanként
                  </th>
                </tr>
                <tr>
                  <td className='price-col-1 border-right '>
                    Általános mozgásvizsgálat (részképességek felmérése)
                  </td>
                  <td className='bg-grey'>90 perc</td>
                  <td className='bg-grey'>12.000 Ft</td>
                </tr>
                <tr>
                  <td className='price-col-1 border-right'>
                    Egyéni mozgásterápia
                  </td>
                  <td>45 perc</td>
                  <td>6.500 Ft</td>
                </tr>
                <tr>
                  <td className='price-col-1 border-right '>
                    Csoportos mozgásterápia (KMT)
                  </td>
                  <td className='bg-grey'>60 perc</td>
                  <td className=' bg-grey'>3.800 Ft (3 főtől)</td>
                </tr>
                <tr>
                  <td className='price-col-1 border-right '>
                    Csoportos bérlet (KMT)
                  </td>
                  <td>4 alkalom</td>
                  <td>14.000 Ft</td>
                </tr>
                <tr>
                  <td className='price-col-1 border-right '>
                    Otthoni mozgássor felírása
                  </td>
                  <td className='bg-grey'>1 alkalom</td>
                  <td className='bg-grey'>5.500 Ft</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <h6 className='price-block-title'>Játékos tanulás árlista</h6>
            <table className='price-table'>
              <tbody>
                <tr className='price-table-header'>
                  <th className='price-col-1'>
                    A vizsgálat/foglalkozás típusa
                  </th>
                  <th className='price-col-2' colspan='2'>
                    Szolgáltatás díja óránként vagy alkalmanként
                  </th>
                </tr>
                <tr>
                  <td className='price-col-1 border-right '>
                    Felmérés matematika
                  </td>
                  <td className='bg-grey'>45-60 perc</td>
                  <td className='bg-grey'>Ingyenes</td>
                </tr>
                <tr>
                  <td className='price-col-1 border-right'>
                    Matematika fejlesztés egyéni
                  </td>
                  <td>45 perc</td>
                  <td>5.500 Ft</td>
                </tr>
                <tr>
                  <td className='price-col-1 border-right '>
                    Játékos matematika (csoportos)
                  </td>
                  <td className='bg-grey'>60 perc</td>
                  <td className='bg-grey'>2.800 Ft (3 főtől)</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <h6 className='price-block-title'>
              Fejlesztő játékos foglalkozások árlista
            </h6>
            <table className='price-table'>
              <tbody>
                <tr className='price-table-header'>
                  <th className='price-col-1'>
                    A vizsgálat/foglalkozás típusa
                  </th>
                  <th className='price-col-2' colspan='2'>
                    Szolgáltatás díja óránként vagy alkalmanként
                  </th>
                </tr>
                <tr>
                  <td className='price-col-1 border-right '>
                    Stresszoldó művészterápia
                  </td>
                  <td className='bg-grey'>60 perc</td>
                  <td className='bg-grey'>3.800 Ft</td>
                </tr>
                <tr>
                  <td className='price-col-1 border-right '></td>
                  <td className='bg-grey'>
                    <i>(3 fő- 5 fő)</i>
                  </td>
                  <td className='bg-grey'>3.200 Ft</td>
                </tr>
                <tr>
                  <td className='price-col-1 border-right'>Csoportos mozgás</td>
                  <td>60 perc</td>
                  <td>2.400 Ft (3 főtől)</td>
                </tr>
                <tr>
                  <td className='price-col-1 border-right '>
                    Csoportos mozgás bérlet
                  </td>
                  <td className='bg-grey'>4 alkalom</td>
                  <td className='bg-grey'>9.000 Ft</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
