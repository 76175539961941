import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getBlogContent } from '../../api/blog';
import './BlogPage.scss';

export default function BlogPage(props) {
  const [blogContent, setContent] = useState();

  const params = useParams();

  useEffect(() => {
    async function apiCall() {
      const apiResponse = await getBlogContent(params.page_name);

      if (apiResponse) {
        setContent(apiResponse.data);
      }
    }
    apiCall();
  }, [params.page_name]);

  return (
    <React.Fragment>
      <div className={'content-block blog-content'}>
        <div className={'dx-card responsive-paddings'}>
          <h2>{blogContent && blogContent.title}</h2>
          {blogContent && (
            <div dangerouslySetInnerHTML={{ __html: blogContent.content }} />
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
