export const navigation = [
  {
    path: '/kik-vagyunk',
    text: 'Kik vagyunk',
    icon: 'fa-regular fa-heart',
  },
  {
    path: '/hogyan-segitunk',
    text: 'Hogyan segítünk',
    icon: 'fa-solid fa-hands-holding-child',
  },
  {
    text: 'Lugas',
    icon: 'fa-solid fa-seedling',
  },
  {
    text: 'Podcast',
    icon: 'fa-solid fa-podcast',
  },
  {
    text: 'Blog',
    path: '/blog',
    icon: 'fa-solid fa-blog',
  },
  {
    text: 'Híreink',
    icon: 'fa-solid fa-newspaper',
    path: '/hirek',
  },
  {
    path: '/kapcsolat',
    text: 'Kapcsolat',
    icon: 'fa-solid fa-map-location',
  },
];

export const homeMenu = [
  {
    text: 'Főoldal',
    path: '/home',
    icon: 'home',
  },
];

export const userMenu = [
  {
    text: 'Profilom',
    path: '/profile',
    icon: 'user',
  },
];
