import 'devextreme/data/odata/store';
import React from 'react';

import PageTitle from '../../components/pageItems/PageTitle';
import './bejelentkezes.scss';

export default function Bejelentkezes() {
  return (
    <React.Fragment>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <PageTitle title={'Bejelentkezés terápiára'} />
          <div>
            <p>
              Kincses Tímea / <strong>+36704185772</strong>
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
