import 'devextreme/data/odata/store';
import React from 'react';
import PageTitle from '../../components/pageItems/PageTitle';
import './aboutus.scss';

export default function AboutUs() {
  return (
    <React.Fragment>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <PageTitle title={'Kik vagyunk?'} />
          <div className={'content-block'}>
            <div>
              <p>
                <strong>Tímea</strong> - óvodapedagógus, mozgásterapeuta
              </p>
              <p className='description-box'>
                Amikor megálmodtam a Palántát, az egyetlen egy életcélom
                lebegett a szemem előtt, hogy minden gyermek, akivel összehoz a
                sors, boldog legyen. Óvodapedagógus vagyok és Mozgásterapeuta.
                Ezen kívül Édesanya, Nő s Feleség. Szeretnék segíteni azoknak a
                családoknak, gyermekeknek, akik kicsit kilógnak a skatulyából.
                Persze nem célom belepasszírozni őket ebbe a mérhetetlen szűkös
                „dobozba”, hiszen én magam sem hiszek abban, hogy bele kell.
                Egyszerűen csak terelgetem Őket, hogy könnyebben boldoguljanak
                ebben a csodálatos színes világban. Évek óta családi
                vállalkozásunk aktív tagja vagyok és most érkezett el az idő,
                hogy belőle egy kis darabot teljesen magaménak érezhetek. A kis
                Tündérkertünkbe várom a kicsi palántákat, hogy velem együtt
                csodálkozzanak rá a világra, hogy velem együtt meg tudják
                tapasztalni, hogy milyen hatalmas erő birtokában vannak. Ez nem
                csupán egy fejlesztő központ, azt szeretnénk, ha minden gyermek
                érezné azt az örömöt, hogy van egy varázslatos hely, ahol nem
                kell védekezni, mindenféle tüske, bántalom ellen. Itt lehetnek
                önmaguk, levehetik a páncéljukat, amiket nap mint nap magukra
                öltenek és úgy fogadjuk el Őket amilyenek. Játszva tanítjuk meg
                Őket a külvilág rejtelmeire, csempésszük bele a mindennapjaikba
                a mozgás örömét, ezáltal észrevétlenül fejlődik tudásuk,
                ügyességük és ők is ugyanúgy boldogan veszik a mindennapos
                akadályokat. Számomra minden gyermek egy igazi csoda.
                Végzettségem nem csupán tudás, hanem egy hivatás. Hiszem, hogy a
                világot a gyermekeket szeretve lehet jobbá tenni. Gyere és
                játssz velünk, játsszuk együtt az életet! Ha még kíváncsi vagy
                Rám, olvass bele blog bejegyzéseimbe, hallgasd podcastjeinket
                vagy csak gyere el a Tündérkertünkbe.
              </p>
              <p>
                <strong>Flóra</strong> - gyógypedagógus
              </p>
              <p className='description-box'>
                Pedagógus családban születtem és már egész kiskoromtól kezdve
                gyerekekkel szerettem volna foglalkozni. Az évek során kialakult
                bennem, hogy pontosan mi szeretnék lenni és a gyógypedagógiára
                esett a választásom. Kitűnővel érettségiztem majd felvételiztem
                a Soproni Egyetemre. Jelenleg másodéves hallgató vagyok. Nagyon
                fontosnak tartom a gyerekekkel való foglalkozást és hiszem, hogy
                megfelelő támogatással és motivációval minden gyermekből ki
                lehet hozni a legjobbat. Legelőször a 3 nálam kisebb
                testvéremmel tapasztalhattam meg az együttjátszások majd a házi
                írások alkalmával, hogy mindenki egy kicsit máshogy gondolkodik,
                de egyáltalán nem rossz ez, hiszen így színes az élet. Tízedikes
                koromtól kezdve korrepetálok, főleg matematikát. Ezekből az
                órákból mindig rengeteg tapasztalatot szerzek és nagyon sokféle
                gondolkodásmódot megismerek. Sokszor az a legnagyobb probléma,
                hogy az alapokat nem sajátítják el megfelelően és emiatt később
                a nehezebb feladatoknál elcsúsznak. Ezért nagyon fontosnak
                tartom az alapok játékos elsajátítását, ezzel kedvet és
                motivációt adva a gyerekeknek.
              </p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
