import { withNavigationWatcher } from './contexts/navigation';
import {
  AboutPage,
  ArlistaPage,
  Bejelentkezes,
  BlogList,
  BlogPage,
  ContactPage,
  HirekPage,
  HomePage,
  ProfilePage,
  SegitunkPage,
  TasksPage,
} from './pages';

const routes = [
  {
    path: '/tasks',
    element: TasksPage,
  },
  {
    path: '/profile',
    element: ProfilePage,
  },
  {
    path: '/home',
    element: HomePage,
  },
  {
    path: '/blog',
    element: BlogList,
  },
  {
    path: '/blogPage/:page_name',
    element: BlogPage,
  },
  {
    path: '/hogyan-segitunk',
    element: SegitunkPage,
  },
  {
    path: '/kik-vagyunk',
    element: AboutPage,
  },
  {
    path: '/kapcsolat',
    element: ContactPage,
  },
  {
    path: '/bejelentkezes',
    element: Bejelentkezes,
  },
  {
    path: '/hirek',
    element: HirekPage,
  },
  {
    path: '/arlista',
    element: ArlistaPage,
  },
];

export default routes.map((route) => {
  return {
    ...route,
    element: withNavigationWatcher(route.element, route.path),
  };
});
