module.exports = {
  ALERT_TIMEOUT: 6000,
  MESSAGE_TIMEOUT: 5000,

  //backendHOST: 'http://localhost:3010',
  backendHOST: process.env.REACT_APP_BACKEND_HOST
    ? process.env.REACT_APP_BACKEND_HOST
    : '', // prod
  LANG: 'HU',
  log_on: 1, //1- only errors 5- full
  axios_header: {
    headers: {
      'Content-Type': 'application/json',
      'x-auth-token': localStorage.token,
    },
  },
};
